import React from "react";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import "../Loader.css";
import "./Dashboard.css";

import { NavLink } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useAuth } from "../../context/AuthContext";

import { twoDecimalPlaces } from "../utils/utilFuctions";
import MerchantPayinLedgerReport from "../MerchantPayinLedgerReport";
import { AreaChart, Area, Tooltip, ResponsiveContainer } from "recharts";
import DashboardReports from "./DashboardReports";
import SuspectedReport2 from "../SuspectedReport2";
const data = [
  { name: "Page A", uv: 4000, pv: 2400, amt: 2400 },
  { name: "Page B", uv: 3000, pv: 1398, amt: 2210 },
  { name: "Page C", uv: 2000, pv: 9800, amt: 2290 },
  { name: "Page D", uv: 2780, pv: 3908, amt: 2000 },
  { name: "Page E", uv: 1890, pv: 4800, amt: 2181 },
  { name: "Page F", uv: 2390, pv: 3800, amt: 2500 },
  { name: "Page G", uv: 3490, pv: 4300, amt: 2100 },
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};
const Dashboard = () => {
  const { user, setTransactionLoaderState } = useAuth();
  const [balance, setbalance] = useState({ unsettledAmount: "0.00" });
  const [balanceValues, setBalanceValues] = useState({
    unsettledAmount: "0.00",
    dispute: "0.00",
    refund: "0.00",
  });

  const [analyticsCards, setAnalyticsCards] = useState({});

  const [reportData, setReportData] = useState([]);
  const [slabData, setSlabData] = useState([]);
  const [analyticsData, setAnalyticsData] = useState({
    succeess_count: "0",
    success_amount: "0.00",
    failed_count: "0",
    failed_amount: "0.00",
    expired_count: "0",
    expired_amount: "0.00",
    request_count: "0",
    request_amount: "0.00",
  });
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [endDate, setEndDate] = useState(new Date());

  const dateFormatter = (d) => {
    const date = new Date(d);
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  //Show Balance
  const BalanceApi = async () => {
    const rawData = {
      merchantId: user.merchantId,
    };

    try {
      setTransactionLoaderState(true);
      const response = await axios.post(
        `https://bumppy.in/payin/api/merchantWalletBalance.php`,
        rawData
      );
      // setbalance(response.data);
      if (response.data.status === "00") {
        console.log(response.data.data);
        setbalance(response.data.data); // Assuming the data property holds the report data
      } else {
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.error("Error fetching report data:", error);
    }
  };

  // Call the API on page load
  useEffect(() => {
    BalanceApi();
  }, []);

  const ViewUPIIntentReport = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        merchant_id: user.merchantId,
      });

      console.log("hii2");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      setTransactionLoaderState(true);
      const res = await fetch(
        `https://payin.remittpe.com/api/Intent/GetMerchantLedgerReport`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == "00") {
        console.log(data.data);
        let unSettled = 0,
          dispute = 0,
          withDrawlAmount = 0;
        data.data.forEach((e) => {
          console.log(e);
          if (e.status == "UNSETTLED") {
            unSettled += parseFloat(e.payin_amount);
          }
        });
        setBalanceValues({
          unsettledAmount: unSettled.toFixed(2),
          dispute: dispute.toFixed(2),
          withDrawlAmount: withDrawlAmount.toFixed(2),
        });
      }
      setTransactionLoaderState(false);
      setLoading(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
      setLoading(false);
    }
  };

  const reportApi = async () => {
    // setLoading(true);
    const rawData = {
      // transactionTypeId: "13",
      // transactionType: type,
      // fromDate: dateFormatter(startDate),
      // toDate: dateFormatter(endDate),
      merchantId: "1003",

      fromDate: dateFormatter(startDate),
      toDate: dateFormatter(endDate),
      //"merchantId":user.merchantId
    };

    try {
      setTransactionLoaderState(true);
      const response = await axios.post(
        ` https://bumppy.in/payin/api/getAllUPIReport.php`,
        rawData
      );

      if (response.status === 200) {
        setReportData(response.data.data); // Assuming the data property holds the report data
      } else {
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.error("Error fetching report data:", error);
    } finally {
      setTransactionLoaderState(false);
      setLoading(false); // Set loading to false when API call completes (success or failure)
    }
  };

  // const [analyticsData, setAnalyticsData] = useState({
  //   succeess_count: '4177',
  //   success_amount: '507948.00',
  //   failed_count: '0',
  //   failed_amount: '0.00',
  //   expired_count: '8726',
  //   expired_amount: '1779907.00',
  //   request_count: '12903',
  //   request_amount: '2287855.00',
  // });

  const [dashboardData1, setDashboardData1] = useState({
    total_requests: 0,
    approved: 0,
    pending: 0,
    rejected: 0,
  });

  const [approvedDashArray, setApprovedDashArray] = useState("0");
  const [pendingDashArray, setPendingDashArray] = useState("0");
  const [rejectedDashArray, setRejectedDashArray] = useState("0");

  const slabDataApi = async () => {
    try {
      const requestBody = {
        merchant_id: user.merchantId,
      };
      setTransactionLoaderState(true);
      const response = await axios.post(
        "https://payin.remittpe.com/api/Intent/GetMerchantPayinAnalyticsV1",
        requestBody
      );

      if (response.data.status === "0") {
        setSlabData(response.data.data);
      } else {
        console.log("Error");
      }
      setTransactionLoaderState(false);
    } catch {
      setTransactionLoaderState(false);
      console.log("Error");
    }
  };

  const getAnalyticsData3 = async () => {
    try {
      const requestBody = {
        merchantId: user.merchantId,
      };
      setTransactionLoaderState(true);
      const response = await axios.post(
        "https://bumppy.in/bumppy.in/payin/api/getPayinAnalyticsData3.php",
        requestBody
      );

      if (response.data.status === "00") {
        setAnalyticsCards(response.data.data);
      } else {
        console.log("Error");
      }
      setTransactionLoaderState(false);
    } catch {
      setTransactionLoaderState(false);
      console.log("Error");
    }
  };

  const AnalyticsDataApi = async () => {
    // setLoading(true);
    const today = new Date().toISOString().slice(0, 10);
    const rawData = {
      merchant_id: user.merchantId,
      // merchantId: "1021",
      from_date: today,
      to_date: today,
    };

    try {
      setTransactionLoaderState(true);
      const response = await axios.post(
        `https://payin.remittpe.com/api/Intent/GetMerchantPayinAnalytics`,
        rawData
      );

      if (response.status === 200) {
        const data = response.data.data;
        console.log(response.data.data);
        setAnalyticsData(response.data.data[0]);

        setDashboardData1({
          total_requests: Number(data.request_count),
          approved: Number(data.succeess_count),
          pending: Number(data.expired_count),
          rejected: Number(data.failed_count),
        });
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.error("Error fetching report data:", error);
    } finally {
      setTransactionLoaderState(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAnalyticsData3();
    reportApi();
    slabDataApi();
    ViewUPIIntentReport();
    AnalyticsDataApi();
  }, []);
  const circleCircumference = 2 * Math.PI * 80;
  useEffect(() => {
    const total =
      dashboardData1.approved +
      dashboardData1.pending +
      dashboardData1.rejected;
    const approvedPercentage = (dashboardData1.approved / total) * 100;
    const pendingPercentage = (dashboardData1.pending / total) * 100;
    const rejectedPercentage = (dashboardData1.rejected / total) * 100;

    // const circleCircumference = 2 * Math.PI * 80;

    setApprovedDashArray(
      `${
        (approvedPercentage / 100) * circleCircumference
      } ${circleCircumference}`
    );
    setPendingDashArray(
      `${
        (pendingPercentage / 100) * circleCircumference
      } ${circleCircumference}`
    );
    setRejectedDashArray(
      `${
        (rejectedPercentage / 100) * circleCircumference
      } ${circleCircumference}`
    );
  }, [dashboardData1]);

  return (
    <>
      <div
        className="p-4 p-lg-5 d-flex flex-column "
        style={{
          backgroundColor: "#F1EFEF",
          fontFamily: "poppins",
        }}
      >
        {loading && (
          <div className="loader-container">
            <div className="loader"></div>
            <div className="loader-text">Please wait...</div>
          </div>
        )}
        {/* Dashboard Top */}
        <div className="row   m-3">
          {/* First */}

          <div className="col-12 col-lg-5  m-0 p-0 ">
            <div className=" card card-height  shadow p-3 m-2 bg-white d-flex flex-column  justify-content-between border-15-black dashboard-height">
              <div className="row">
                <div className="col-12 p-0 m-0 d-flex">
                  {/* <p className="text-3-2 mx-3"> Payin Analytics Data</p> */}
                  <span>
                    <h4 className="mx-3">Payin Analytics Data</h4>
                  </span>{" "}
                  <span className="  text-primary d-flex align-items-center text-5">
                    Today
                  </span>
                </div>
                <div className=" col-6">
                  <div className="p-2  m-2 bg-green border-15">
                    <div>
                      <div className="text-3-2 text-start">
                        {" "}
                        {analyticsData?.succeess_count}
                      </div>
                      <div>
                        <span
                          style={{
                            display: "inline-block",
                            width: "10px",
                            height: "10px",
                            backgroundColor: "rgba(1, 195, 125, 1)",
                            marginRight: "5px",
                          }}
                        ></span>
                        <span className="text-5 text-gray">Succeess</span>
                      </div>
                    </div>
                  </div>
                  <div className="p-2 m-2 border-15 bg-light-red">
                    <div>
                      <div className="text-3-2 text-start">
                        {" "}
                        {analyticsData?.failed_count}
                      </div>
                      <div>
                        <span
                          style={{
                            display: "inline-block",
                            width: "10px",
                            height: "10px",
                            backgroundColor: "rgba(215, 107, 102, 1)",
                            marginRight: "5px",
                          }}
                        ></span>
                        <span className="text-5 text-gray">Failed</span>
                      </div>
                    </div>
                  </div>

                  <div className="p-2 m-2 border-15 bg-light-yellow">
                    <div>
                      <div className="text-3-2 text-start">
                        {" "}
                        {analyticsData?.expired_count}
                      </div>
                      <div>
                        <span
                          style={{
                            display: "inline-block",
                            width: "10px",
                            height: "10px",
                            backgroundColor: "rgba(250, 152, 0, 1)",
                            marginRight: "5px",
                          }}
                        ></span>
                        <span className="text-5 text-gray">Expired</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" col-6 d-flex align-items-center justify-content-center">
                  <>
                    <div
                      className="donut-chart"
                      style={{ textAlign: "center" }}
                    >
                      <svg width="100%" height="auto" viewBox="0 0 350 350">
                        <circle
                          cx="125"
                          cy="125"
                          r="100"
                          stroke="#E7E7E7"
                          strokeWidth="20"
                          fill="transparent"
                        />
                        <circle
                          cx="125"
                          cy="125"
                          r="100"
                          stroke="rgba(1, 195, 125, 1)"
                          strokeWidth="20"
                          fill="transparent"
                          strokeDasharray={approvedDashArray}
                          strokeDashoffset="0"
                        />
                        <circle
                          cx="125"
                          cy="125"
                          r="100"
                          stroke="rgba(250, 152, 0, 1)"
                          strokeWidth="20"
                          fill="transparent"
                          strokeDasharray={pendingDashArray}
                          strokeDashoffset={`-${
                            (dashboardData1.approved /
                              (dashboardData1.approved +
                                dashboardData1.pending +
                                dashboardData1.rejected)) *
                            circleCircumference
                          }`}
                        />
                        <circle
                          cx="125"
                          cy="125"
                          r="100"
                          stroke="rgba(215, 107, 102, 1)"
                          strokeWidth="20"
                          fill="transparent"
                          strokeDasharray={rejectedDashArray}
                          strokeDashoffset={`-${
                            ((dashboardData1.approved +
                              dashboardData1.pending) /
                              (dashboardData1.approved +
                                dashboardData1.pending +
                                dashboardData1.rejected)) *
                            circleCircumference
                          }`}
                        />
                        <text
                          x="125"
                          y="125"
                          textAnchor="middle"
                          fontSize="22"
                          fill="black"
                          fontWeight="bold"
                        >
                          {analyticsData?.success_amount}
                        </text>
                        <text
                          x="125"
                          y="145"
                          textAnchor="middle"
                          fontSize="12"
                          fill="black"
                        >
                          Success Amount
                        </text>
                      </svg>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
          {/* Second */}
          <div className=" col-12 col-lg-3  m-0 p-0 ">
            <div className="m-2 align-items-stretch card card-height shadow p-3 bg-white border-15-black dashboard-height d-flex flex-column justify-content-between">
              {/* <p className="text-3-2">Merchant Balance</p> */}
              <h4>Merchant Balance</h4>

              <div className="row ">
                <div className="col-6">
                  <div className="text-3-2">
                    {twoDecimalPlaces(balanceValues.unsettledAmount)}
                  </div>
                  <div className="text-footer">Unsettled Balance</div>
                </div>
                <div className="col-6">
                  {" "}
                  <ResponsiveContainer height={50}>
                    <AreaChart
                      data={data}
                      margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <defs>
                        <linearGradient
                          id="colorUv"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor="rgba(73, 166, 119, 1)"
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor="rgba(255, 255, 255, 0)"
                            stopOpacity={0}
                          />
                        </linearGradient>
                      </defs>
                      <Tooltip
                        content={<CustomTooltip />}
                        cursor={{ strokeDasharray: "3 3" }}
                      />
                      <Area
                        type="linier"
                        dataKey="uv"
                        stroke="rgba(93, 180, 138, 1)"
                        fill="url(#colorUv)"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <hr className="p-0 m-0" />
              <div className="row ">
                <div className="col-6">
                  <div className="text-3-2">
                    {twoDecimalPlaces(analyticsCards.totalDispute)}
                  </div>
                  <div className="text-footer">Total Dispute</div>
                </div>
                <div className="col-6">
                  {" "}
                  <ResponsiveContainer height={50}>
                    <AreaChart
                      data={data}
                      margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <defs>
                        <linearGradient
                          id="colorUv2"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor="rgba(235, 148, 162, 1)"
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor="rgba(235, 148, 162, 0)"
                            stopOpacity={0}
                          />
                        </linearGradient>
                      </defs>
                      <Tooltip
                        content={<CustomTooltip />}
                        cursor={{ strokeDasharray: "3 3" }}
                      />
                      <Area
                        type="linier"
                        dataKey="uv"
                        stroke="rgba(235, 116, 135, 1)"
                        fill="url(#colorUv2)"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <hr className="p-0 m-0" />
              <div className="row ">
                <div className="col-6">
                  <div className="text-3-2">
                    {twoDecimalPlaces(analyticsCards.totalRefund)}
                  </div>
                  <div className="text-footer">Total Refund</div>
                </div>
                <div className="col-6">
                  {" "}
                  <ResponsiveContainer height={50}>
                    <AreaChart
                      data={data}
                      margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <defs>
                        <linearGradient
                          id="colorUv"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor="#8884d8"
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor="#8884d8"
                            stopOpacity={0}
                          />
                        </linearGradient>
                      </defs>
                      <Tooltip
                        content={<CustomTooltip />}
                        cursor={{ strokeDasharray: "3 3" }}
                      />
                      <Area
                        type="linier"
                        dataKey="uv"
                        stroke="rgba(93, 180, 138, 1)"
                        fill="url(#colorUv)"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <hr className="p-0 m-0" />
              <div className="d-flex justify-content-center">
                <div className="btn bg-green-filter text-white">Details</div>
              </div>
            </div>
          </div>
          {/* 3rd */}
          <div className="col-lg-4 col-12  m-0 p-0 ">
            <div className="m-2  card card-height shadow  p-3 bg-white d-flex flex-column  justify-content-between border-15-black dashboard-height">
              <div className="d-flex align-items-center">
                {/* <p className="text-3-2">Total Request</p> */}
                <span>
                  <h4 className="mx-3">Total Request</h4>
                </span>{" "}
                <span className="  text-primary d-flex align-items-center text-5">
                  Today
                </span>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="bg-light border-15 p-3">
                    <div className="d-flex justify-content-center">
                      Request Count
                    </div>
                    <div className="d-flex justify-content-center text-3-2">
                      {analyticsData?.request_count}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="bg-light border-15 p-3">
                    <div className="d-flex justify-content-center">
                      Request Amount
                    </div>
                    <div className="d-flex justify-content-center text-3-2">
                      {analyticsData?.request_amount}
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-gred-voilate border-15 px-1 ">
                <div className="p-3">
                  {/* <div className="d-flex justify-content-center text-3-2">
                    470
                  </div> */}

                  <div className="d-flex justify-content-center">
                    Total Request
                  </div>
                </div>
                <div>
                  <ResponsiveContainer height={50}>
                    <AreaChart
                      data={data}
                      margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <defs>
                        <linearGradient
                          id="colorUv4"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor="rgba(230, 229, 251, 1)"
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor="rgba(254, 253, 255, 1)"
                            stopOpacity={0}
                          />
                        </linearGradient>
                      </defs>
                      <Tooltip
                        content={<CustomTooltip />}
                        cursor={{ strokeDasharray: "3 3" }}
                      />
                      <Area
                        type="linier"
                        dataKey="uv"
                        stroke="rgba(151, 143, 237, 1)"
                        fill="url(#colorUv4)"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
          {/* Settlement Slabs */}
          <div className=" col-12  m-0 p-0 ">
            <div className="m-2 align-items-stretch card card-height shadow p-3 bg-white border-15-black dashboard-height d-flex flex-column justify-content-between">
              {/* <p className="text-3-2">Merchant Balance</p> */}
              <h4>Settlement Amount</h4>

              {slabData.map((e) => (
                <React.Fragment>
                  <div className="row ">
                    <div className="col-6">
                      <div className="text-3-2">{e.slab}</div>
                      <div className="text-footer">
                        {" "}
                        {twoDecimalPlaces(e.total_payin)}{" "}
                      </div>
                    </div>
                    <div className="col-6">
                      {" "}
                      <ResponsiveContainer height={50}>
                        <AreaChart
                          data={data}
                          margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                          }}
                        >
                          <defs>
                            <linearGradient
                              id="colorUv"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="1"
                            >
                              <stop
                                offset="5%"
                                stopColor="rgba(73, 166, 119, 1)"
                                stopOpacity={0.8}
                              />
                              <stop
                                offset="95%"
                                stopColor="rgba(255, 255, 255, 0)"
                                stopOpacity={0}
                              />
                            </linearGradient>
                          </defs>
                          <Tooltip
                            content={<CustomTooltip />}
                            cursor={{ strokeDasharray: "3 3" }}
                          />
                          <Area
                            type="linier"
                            dataKey="uv"
                            stroke="rgba(93, 180, 138, 1)"
                            fill="url(#colorUv)"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                  <hr className="p-0 m-0" />
                </React.Fragment>
              ))}
            </div>
          </div>
          {/* 4TH */}
          {/* <div className="col-lg-6 col-12  m-0 p-0 ">
            <div className="card card-height  p-4 m-2 shadow ">
              <img
                style={{
                  width: "20%",
                  height: "auto",
                }}
                src={require("../../assets/images/pgblue.png")}
              />
              <h4>Pay In</h4>
              <div>
                Accept domestic and international payments with 200+ payment
                options .Get paid the next day{" "}
              </div>
              <div>
                <NavLink
                  className="mt-3 btn btn-lg btn-block btn-primary"
                  style={{
                    border: "none",
                    color: "white",
                  }}
                  to="/developer/upi-intent-report"
                >
                  Access
                </NavLink>
              </div>
            </div>
          </div> */}

          {/* 5th */}
          {/* <div className="col-lg-6 col-12 m-0 p-0">
            <div className=" card card-height  p-4 m-2 shadow">
              <img
                style={{
                  width: "20%",
                  height: "auto",
                }}
                src={require("../../assets/images/moneyblue.png")}
              />
              <h4>Payout</h4>
              <div>
                Send payments instantly to any bank account , UPI VPA , CARD or
                Wallet
              </div>
              <div>
                <NavLink
                  className="mt-3 btn btn-lg btn-block btn-primary"
                  style={{
                    border: "none",
                    color: "white",
                  }}
                  to="/developer/payout"
                >
                  Access
                </NavLink>
              </div>
            </div>
          </div> */}
        </div>

        {/* <div className="row  m-4" id="payment-mobile-view">
          <div className="col-6 card p-4 shadow ">
            <img
              style={{
                width: "20%",
                height: "auto",
              }}
              src={require("../../assets/images/pgblue.png")}
            />
            <h4>Pay In</h4>
            <div>
              Accept domestic and international payments with 200+ payment
              options .Get paid the next day{" "}
            </div>
            <div>
              <NavLink
                className="mt-3 btn btn-lg btn-block btn-primary"
                style={{
                  border: "none",
                  color: "white",
                }}
                to="/developer/upi-intent-report"
              >
                Access
              </NavLink>
            </div>
          </div>
          <div className="col-6 card p-4 shadow">
            <img
              style={{
                width: "20%",
                height: "auto",
              }}
              src={require("../../assets/images/moneyblue.png")}
            />
            <h4>Payout</h4>
            <div>
              Send payments instantly to any bank account , UPI VPA , CARD or
              Wallet
            </div>
            <div>
              <NavLink
                className="mt-3 btn btn-lg btn-block btn-primary"
                style={{
                  border: "none",
                  color: "white",
                }}
                to="/developer/payout"
              >
                Access
              </NavLink>
            </div>
          </div>
        </div> */}
        {/* <div>
          <h3>Merchant Dashboard</h3>
          <div
            className="col d-flex flex-row "
            id="merchant-dashboard-mobile-view"
          >
     
            <div className="col card p-4 m-3 shadow">
              <img />
              <h4>
                <b>{twoDecimalPlaces(balanceValues.unsettledAmount)}</b>
              </h4>
              <div>Unsettled Balance</div>
            </div>
            <div className="col card p-4 m-3 shadow">
              <img />
              <h4>
                <b>{twoDecimalPlaces(balanceValues.dispute)}</b>
              </h4>
              <div>Dispute</div>
            </div>
            <div className="col card p-4 m-3 shadow">
              <img />
              <h4>
                <b>{twoDecimalPlaces(balanceValues.withDrawlAmount)}</b>
              </h4>
              <div>Available to withdraw</div>
              <div>
                <button
                  className="mt-3 btn btn-lg btn-block btn-primary"
                  style={{
                    border: "none",
                    color: "white",
                  }}
                >
                  withdraw
                </button>
              </div>
            </div>
          </div>
        </div> */}
        <SuspectedReport2 />

        <DashboardReports />
      </div>
    </>
  );
};

export default Dashboard;
