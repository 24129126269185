import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import Swal from "sweetalert2";
import swal from "sweetalert";
import "bootstrap/dist/css/bootstrap.css";
import "./Login.css";
import "./Loader.css";
import image4 from "../images/Group 3 (1).png";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const Login = () => {
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [isPhoneLogin, setIsPhoneLogin] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useHistory();
  const { login } = useAuth();

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [image4, image4, image4];
  const headings = ["", "", ""];
  const descriptions = [" ", " ", " "];

  useEffect(() => {
    const changeImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const interval = setInterval(changeImage, 3000); // Change image every 3 seconds (adjust as needed)

    return () => clearInterval(interval);
  }, []);

  const formValidation = () => {
    if (!email && !password) {
      setEmailError("* Please enter a valid Email ID");
      setPasswordError("* Please Enter Password.");
      return;
    } else if (!email) {
      setEmailError("* Please enter a valid Email ID");
      return;
    } else if (!password) {
      setPasswordError("* Please Enter Password");
      return;
    }

    handleLogin();
  };

  const handleLogin = async () => {
    setLoading(true);
    //  const hash =  crypto.createHash("md5").update(password).digest("hex");
    try {
      const response = await axios.post(
        "https://bumppy.in/payin/api/merchantLogin.php",
        {
          merchantUserName: email,
          merchantPassword: password,
          ipaddress: "12",
          latitude: "346346",
          longitude: "457457",
          device: "WEB",
        }
      );

      if (response.data.status === "00") {
        // Successful login
        const userData = response.data.data;
        login(userData);
        navigate.push("/dashboard");
        //  navigate('/Pay');
      } else {
        // Failed login
        Swal.fire({
          icon: "error",
          title: "Failed Login",
          text: "Please check your Email and Password.",
        });
      }
    } catch (error) {
      // Handle login error and display a SweetAlert popup
      Swal.fire({
        icon: "error",
        title: "Failed Login",
        text: "Please check your Email and Password.",
      });
    } finally {
      setLoading(false); // Set loading to false when API call completes (success or failure)
    }
  };

  const handleSendOtp = () => {
    setIsOtpSent(true);
    merchantOtpLogin();
  };

  const merchantOtpLogin = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Basic cGd1c2VybmV3OlRlc3RAMTIzNDU=");

      var raw = JSON.stringify({
        username: phone
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://payin.remittpe.com/api/Intent/merchantOtpLogin`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "00") {
        swal("Success", "Otp Sent Successfully", "success");
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const LoginOtpSubmission = async () => {
    setLoading(true);
    //  const hash =  crypto.createHash("md5").update(password).digest("hex");
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Basic cGd1c2VybmV3OlRlc3RAMTIzNDU=");

      var raw = JSON.stringify({
        username: phone,
        otp: otp
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://payin.remittpe.com/api/Intent/merchantLoginOtpSubmission`,
        requestOptions
      );

      const data = await res.json();

      if (data.status === "00") {
        // Successful login
        const userData = data.data;
        login(userData);
        navigate.push("/dashboard");
        //  navigate('/Pay');
        Swal.fire({
          icon: "Success",
          title: data.msg,
          text: data.msg,
        });
      } else {
        // Failed login
        Swal.fire({
          icon: "error",
          title: "Failed Login",
          text: data.msg,
        });
      }
    } catch (error) {
      // Handle login error and display a SweetAlert popup
      Swal.fire({
        icon: "error",
        title: "Failed Login",
        text: "Please check your Email and Password.",
      });
    } finally {
      setLoading(false); // Set loading to false when API call completes (success or failure)
    }
  };

  useEffect(()=>{
    if(otp.length==6){
      LoginOtpSubmission(otp);
    }
  },[otp]);

  return (
    <>
      <div
        className="container-fluid px-0"
        style={{ fontFamily: "Nunito, sans-serif", width: "100%" }}
      >
        <div className="row m-0 p-0" style={{ height: "100vh" }}>
          <div
            className="col-12 col-lg-6 d-flex justify-content-center align-items-center"
            style={{
              background: "white",
            }}
          >
            <img
              // src={require("../assets/images/LoginImage.png")}
              alt="Login Illustration"
              style={{ width: "80%", height: "auto" }}
            />
          </div>

          <div
            className="col-12 col-lg-6 d-flex justify-content-center align-items-center"
            style={{ backgroundColor: "#C2D9FF" }}
          >
            <div
              className="card shadow-lg"
              style={{
                width: "400px",
                padding: "30px",
                borderRadius: "10px",
                backgroundColor: "white",
              }}
            >
              <div style={{ fontSize: "24px", fontWeight: "bold" }}>
                {isPhoneLogin ? "Login with Phone" : "Login to your account"}
              </div>

              {!isPhoneLogin ? (
                <>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="form-control"
                      style={{
                        fontSize: "14px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                      placeholder="Enter your Email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailError("");
                      }}
                    />
                    {emailError && (
                      <p style={{ color: "#C00A10" }}>{emailError}</p>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      id="password"
                      className="form-control"
                      style={{
                        fontSize: "14px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                      placeholder="Enter your Password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setPasswordError("");
                      }}
                    />
                    {passwordError && (
                      <p style={{ color: "#C00A10" }}>{passwordError}</p>
                    )}
                  </div>

                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div style={{ fontSize: "14px" }}>
                      <a href="#" style={{ color: "#0066cc" }}>
                        FORGOT PASSWORD?
                      </a>
                    </div>
                  </div>

                  <button
                    className="btn btn-primary w-100"
                    style={{
                      borderRadius: "50px",
                      backgroundColor: "#007bff",
                      border: "none",
                      fontSize: "16px",
                      padding: "10px",
                    }}
                    onClick={formValidation}
                  >
                    LOG IN
                  </button>

                  <button
                    className="btn btn-outline-primary w-100 mt-2"
                    style={{
                      borderRadius: "50px",
                      fontSize: "16px",
                      padding: "10px",
                    }}
                    onClick={() => setIsPhoneLogin(true)}
                  >
                    LOG IN WITH PHONE
                  </button>
                </>
              ) : (
                <>

                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      className="form-control"
                      style={{
                        fontSize: "14px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                      placeholder="Enter your Phone Number"
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                        setPhoneError("");
                      }}
                    />
                    {phoneError && (
                      <p style={{ color: "#C00A10" }}>{phoneError}</p>
                    )}
                  </div>

                  {isOtpSent && (
                  <div className="mb-3">
                  <label htmlFor="otp" className="form-label">
                    Enter OTP
                  </label>
                  <input
                    type="text"
                    id="otp"
                    className="form-control"
                    style={{
                      fontSize: "14px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                    }}
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => {
                      const enteredOtp = e.target.value;
                      setOtp(enteredOtp);
                      setOtpError("");
                    }}
                  />
                  {otpError && <p style={{ color: "#C00A10" }}>{otpError}</p>}
                </div>
                
                  )}

                  <button
                    className="btn btn-primary w-100"
                    style={{
                      borderRadius: "50px",
                      backgroundColor: "#007bff",
                      border: "none",
                      fontSize: "16px",
                      padding: "10px",
                    }}
                    onClick={handleSendOtp}
                  >
                    SEND OTP
                  </button>

                  <button
                    className="btn btn-outline-primary w-100 mt-2"
                    style={{
                      borderRadius: "50px",
                      fontSize: "16px",
                      padding: "10px",
                    }}
                    onClick={() => {
                      setIsPhoneLogin(false);
                      setIsOtpSent(false); // Reset OTP state
                    }} // Switch back to Email Login
                  >
                    LOG IN WITH EMAIL
                  </button>
                </>
              )}

              <div className="text-center mt-3" style={{ fontSize: "12px" }}>
                <p>
                  Protected by reCAPTCHA. Google Privacy policy & Terms of Service apply
                  <a href="#" style={{ color: "#0066cc" }}>
                    Click here
                  </a>
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
};

export default Login;