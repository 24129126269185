import React from "react";
import { useAuth } from "../context/AuthContext";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router-dom";
import "./Loader.css";
import axios from "axios";
import { Pagination } from "./utils/Pagination";
import Swal from "sweetalert2";
import swal from "sweetalert";
import ComingSoonImage from "../assets/animation/Coming Soon (1).json";
import Lottie from "lottie-react";
import { Button, Form } from "react-bootstrap";
import { ExportToExcel, excelMapping, now } from "./utils/utilFuctions";
import Image2 from "../assets/images/Add Load.png"

export const columns = [
  {
    dataField: "id",
    text: "ID",
    color: "#586275",
  },
  {
    dataField: "fk_user_id",
    text: "User ID",
    color: "#586275",
  },
  {
    dataField: "payin_amount",
    text: "Payin Amount",
    color: "#586275",
  },
  {
    dataField: "payin_charge",
    text: "Payin Charge",
    color: "#586275",
  },
  {
    dataField: "payin_gst",
    text: "Payin GST",
    color: "#586275",
  },
  {
    dataField: "settlement_time",
    text: "Settlement Time",
    color: "#586275",
  },
  {
    dataField: "indate",
    text: "In Date",
    color: "#586275",
  },
  {
    dataField: "status",
    text: "Status",
    color: "#586275",
  },
];

const SettlementPay = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [data, setData] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [endDate, setEndDate] = useState(new Date());
  const [settlementButton, setsettlementButton] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [total, setTotal] = useState(0);
  const [pages, setPages] = useState(0);

  const location = useLocation();
  const InternalMerchentID = new URLSearchParams(location.search).get(
    "InternalMerchentID"
  );
  const InternalMerchentName = new URLSearchParams(location.search).get(
    "InternalMerchentName"
  );

  const dateFormatter = (d) => {
    const date = new Date(d);
    return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
      }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

 
  const reportApi = async () => {
    setLoading(true);
    const rawData = {
      //"merchantId": "2",
      merchantId: InternalMerchentID,
      fromDate: dateFormatter(startDate),
      toDate: dateFormatter(endDate),
    };

    try {
      const response = await axios.post(
        `https://bumppy.in/payin/api/sdkClientSettlementRecords.php`,
        rawData
      );

      if (response.data.status === "00") {
        setReportData(response.data.data); 
        setsettlementButton("true");
      } else {
      }
    } catch (error) {
      console.error("Error fetching report data:", error);
    } finally {
      setLoading(false); 
    }
  };

  const updateSettlementStatus = async (ledgerId, status) => {
    console.log("ledgerId", ledgerId, "status", status);
    try {
      let apiStatus = status === "SETTLED" ? "UNSETTLED" : "SETTLED";
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        ledgerId: ledgerId,
        status: apiStatus,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://payin.remittpe.com/api/Intent/updatePayinSettlement",
        requestOptions
      );

      const responseData = await res.json();

      if (responseData.status === "00") {
        Swal.fire({
          icon: "success",
          title: "SUCCESS",
          text: responseData.msg,
        });
        ViewUPIIntentReport();
      } else {
        console.error("Error adding webhook:", responseData.message);
      }
    } catch (error) {
      console.error("Error adding webhook:", error);
    }
  };

  const calculateSettlement = async (ledgerId) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        ledgerId: ledgerId,
        status: "",
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://bumppy.in/payin/api/calculatePayinSettlement.php",
        requestOptions
      );

      const responseData = await res.json();

      if (responseData.status === "00") {
        Swal.fire({
          icon: "success",
          title: "SUCCESS",
          text: responseData.msg,
        });
        ViewUPIIntentReport();
      } else {
        console.error("Error adding webhook:", responseData.message);
      }
    } catch (error) {
      console.error("Error adding webhook:", error);
    }
  };

  const initiateSettlement = async (ledgerId, settlement, status) => {
    try {
      let apiStatus = status === "N" ? "Y" : "N";
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        ledgerId: ledgerId,
        settlement: settlement,
        status: apiStatus,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        "https://bumppy.in/payin/api/initiatePayinSettlement.php",
        requestOptions
      );

      const responseData = await res.json();
     if (responseData.status == "00") {
        Swal.fire({
          icon: "success",
          title: "SUCCESS",
          text: responseData.msg,
        });
        ViewUPIIntentReport();
      } else {
        console.error("Error adding webhook:", responseData.message);
      }
    } catch (error) {
      console.error("Error adding webhook:", error);
    }
  };

  const ViewUPIIntentReport = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        merchant_id: InternalMerchentID,
        fromDate: dateFormatter(startDate),
        toDate: dateFormatter(endDate),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://payin.remittpe.com/api/Intent/GetMerchantLedgerReport`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setData(
          data.data.map((e) => ({
            ...e,
            settlementAmount:
              parseFloat(e.payin_amount) -
              parseFloat(e.payin_charge) -
              parseFloat(e.payin_gst),
          }))
        );
        setDataToDisplay(
          data.data.map((e) => ({
            ...e,
            settlementAmount:
              parseFloat(e.payin_amount) -
              parseFloat(e.payin_charge) -
              parseFloat(e.payin_gst),
          }))
        );
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    ViewUPIIntentReport();
  }, []);

  const PayAllApi = async () => {
    setLoading(true);
    const rawData = {
      //   merchantId: "2",
      merchantId: InternalMerchentID,
      fromDate: dateFormatter(startDate),
      toDate: dateFormatter(endDate),
    };

    try {
      const response = await axios.post(
        ` https://bumppy.in/payin/api/sdkClientSettlementAdmin.php`,
        rawData
      );

      if (response.data.status === "00") {
        setReportData(response.data.data);
      } else {
      }
    } catch (error) {
      console.error("Error fetching report data:", error);
    } finally {
      setLoading(false); 
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    reportApi();
  };

  const handleSettleConfirmation = (id, status) => {
    swal({
      title: "Are you sure?",
      text: `You are about to ${status === "SETTLED" ? "Unsettle" : "Settle"
        } the entry with ID ${id}.`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willProceed) => {
      if (willProceed) {
        updateSettlementStatus(id, status);
      }
    });
  };

  const TopUp = (id) => {
    swal({
      title: "Are you sure?",
      text: `You want to Settle the Amount with ID ${id}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        payinAmountSettlement(id);
      }
    });
  };

  const payinAmountSettlement = async (id , settlementAmount) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Basic cGd1c2VybmV3OlRlc3RAMTIzNDU=");

      var raw = JSON.stringify({
        merchantId: user.merchantId,
        settlementId: id,
        amount: settlementAmount,
        merchantSecret: "wetcvb346346dfgdryer5ye5y"
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `https://payin.remittpe.com/api/Intent/payinAmountSettlement`,
        requestOptions
      );
      const data = await res.json();

      if (data.status == "00") {
        swal("Success", data.msg, "success");
     } else if (data.status == "01") {
        swal("Failed", data.msg, "error");
      } else {
        swal("Failed", data.msg?data.msg:"Something went wrong", "warning");
      }
    }catch(err){
      swal("Failed", data.msg?data.msg:"Something went wrong", "warning");
    }
  };


  return (
    <div
      className=" p-4"
      style={{ backgroundColor: "#F1EFEF", fontFamily: "poppins" }}
    >
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
          <div className="loader-text">Please wait...</div>
        </div>
      )}
      <div className="card shadow rounded m-2" style={{ background: "white" }}>
        <div className="row m-2 p-0 pt-4 align-items-center" id="parentFilters" style={{ background: "white" }}>
          <div
            className="m-0 p-0 pl-2 flex-grow-1 d-flex "
            style={{
              fontFamily: "Poppins",
              fontSize: "32px",
              fontWeight: "600",
              lineHeight: "48px",
              letterSpacing: "0em",
              textAlign: "left",
              padding: "0px",
              color: "#2c3e50",
              flex: 1,
              fontWeight: "bolder",
              fontfamily: "Poppins !impotent",
              fontSize: "28px",
              minWidth: "150px",
            }}
          >
            Settlement Pay :  <span className="text-success">{InternalMerchentName}</span>
          </div>
          <div id="filters" style={{ gap: 3, display: "flex", background: "white" }}>
            <Form.Group
              className="p-0 m-0 "
              id="parentDatePicker"
              style={{ minWidth: "250px", display: "flex", gap: 3 }}
            >
              <DatePicker
                className="form-control rounded-1 d-flex flex-grow-1"
                style={{
                  borderColor: "#ccc",
                }}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />

              <DatePicker
                className="form-control rounded-1 d-flex flex-grow-1"
                style={{
                  borderColor: "#ccc",
                }}
                selected={endDate}
                onChange={(date) => setEndDate(date)}
              />
            </Form.Group>
            <div className="d-flex align-items-center" id="searchButton">
              <Button
                className=" btn  btn-lg d-flex flex-grow-1 "
                style={{ fontSize: "0.7rem",background: "#2c3e50" }}
                onClick={handleSubmit}
              >
                {"Search"}
              </Button>
              {settlementButton && (
                <>
                  <div className="mt-4 col-12 col-lg-2">
                    <button
                      onClick={PayAllApi()}
                      className=" btn btn-lg btn-block btn-danger"
                      style={{ width: "100%" }}
                    >
                      All Settlement
                    </button>
                  </div>
                </>
              )}
            </div> <div
              className=" mb-3 d-flex align-items-center justify-content-center "
              style={{
                cursor: "pointer",
                border: "1px solid",
                marginTop: "15px",
                width: "11rem",
                alignItems: "center",
                backgroundColor: "rgba(43, 193, 85, 0.1)",
                color: "rgba(43, 193, 85, 1)",
                bordercolor: "rgba(43, 193, 85, 1)",
                borderRadius: ".4rem",
                minWidth: "170px",
              }}
              onClick={() => {
                ExportToExcel(
                  `payin_ledger_Report_${now()}`,
                  excelMapping(data, columns)
                );
              }}
            >
              <img
                alt=""
                src={require("../assets/images/excel.png")}
                style={{ width: "1.6rem", marginLeft: "15px" }}
              />
              <strong
                className="d-flex align-items-center m-2 "
                style={{ minWidth: "120px", fontSize: "0.8rem" }}
              > Export to Excel
              </strong>
            </div>
          </div>
        </div>
        <div
          className=" bg-white p-2 " >
          <table className="table" style={{ fontFamily: "poppins" }}>
            <thead>
              <tr>
                <th
                  className=" text-white text-center py-3 "
                  style={{ background: "#2c3e50" }}
                >Id
                </th>
                <th className=" text-white text-center py-3 "
                   style={{ background: "#2c3e50" }}>
                  Payin Amount
                </th>
                <th className=" text-white text-center py-3"
                   style={{ background: "#2c3e50" }}>
                  Payin Charge
                </th>
                <th className=" text-white text-center py-3 "
                   style={{ background: "#2c3e50" }}>
                  Payin Gst
                </th>
                <th className=" text-white text-center py-3 "
                   style={{ background: "#2c3e50" }}>
                  Settlement Amount
                </th>
                <th className=" text-white text-center py-3 "
                   style={{ background: "#2c3e50" }}>
                  Transaction time
                </th>
                <th className=" text-white text-center py-3 "
                   style={{ background: "#2c3e50" }}>
                  Settlement time
                </th>
                <th className=" text-white text-center py-3 "
                   style={{ background: "#2c3e50" }}>
                  Last Updated
                </th>
                <th className=" text-white text-center py-3 "
                   style={{ background: "#2c3e50" }}>
                  Status
                </th>
                <th className=" text-white text-center py-3"
                   style={{ background: "#2c3e50" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="9" className="text-center">
                    Loading...
                  </td>
                </tr>
              ) : (
                dataToDisplay.map((item) => (
                  <tr key={item.UserName}>
                    <td className="text-center align-middle">
                      {item.id || "NA"}
                    </td>
                    <td className="text-center align-middle">
                      {item.payin_amount || "NA"}
                    </td>
                    <td className="text-center align-middle">
                      {item.payin_charge !== undefined
                        ? parseFloat(item.payin_charge).toFixed(2)
                        : "NA"}
                    </td>
                    <td className="text-center align-middle">
                      {item.payin_gst !== undefined
                        ? parseFloat(item.payin_gst).toFixed(2)
                        : "NA"}
                    </td>
                    <td className="text-center align-middle">
                      {item.settlementAmount !== undefined
                        ? parseFloat(item.settlementAmount).toFixed(2)
                        : "NA"}
                    </td>
                    <td className="text-center align-middle">
                      {item.settlement_time}
                    </td>
                    <td className="text-center align-middle">
                      {item.settlement_time == "5PM-8AM"
                        ? "03:00 PM"
                        : "11:00 PM"}
                    </td>
                    <td className="text-center align-middle">{item.indate}</td>
                    <td className="text-center align-middle">
                      {item.status !== undefined && item.status === "SETTLED" ? (
                        <label
                          style={{
                            padding: "8px",
                            border: "1px solid",
                            height: "2rem",
                            width: "100px",
                            backgroundColor: "rgba(43, 193, 85, 0.1)",
                            color: "rgba(43, 193, 85, 1)",
                            borderColor: "rgba(43, 193, 85, 1)",
                            borderRadius: ".4rem",
                            fontSize: ".8rem",
                            textAlign: "center",
                          }}
                          className="badge badge-success"
                        >
                          {"Settled"}
                        </label>
                      ) : item.status !== undefined && item.status === "UNSETTLED" ? (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            width: "100px",
                            backgroundColor: "rgba(255, 0, 0, 0.1)",
                            color: "rgba(255, 0, 0, 1)",
                            border: "1px solid rgba(255, 0, 0, 1)",
                            borderColor: "rgba(255, 0, 0, 1)",
                            fontSize: "0.8rem",
                            textAlign: "center",
                          }}
                          className="badge badge-danger"
                        >
                          {"Unsettled"}
                        </label>
                      ) : (
                        <label
                          style={{
                            padding: "8px",
                            borderRadius: ".4rem",
                            width: "100px",
                            backgroundColor: "rgba(255, 238, 0, 0.1)",
                            color: "rgba(255, 196, 0, 1)",
                            border: "1px solid rgba(255, 238, 0, 1)",
                            borderColor: "rgba(255, 238, 0, 1)",
                            fontSize: "0.8rem",
                            textAlign: "center",
                          }}
                          className="badge badge-warning"
                        >
                          {"Unsettled"}
                        </label>
                      )}
                    </td>
                    <td className="text-center align-middle">
                      <Button
                        className="btn"
                        style={{
                          width: "150px",
                          backgroundColor: "#427D9D",
                          color: "white",
                          border: "none",
                        }}
                        onClick={() => handleSettleConfirmation(item.id, item.status)}
                      >
                        {item.status === "SETTLED" ? "UNSETTLE" : "SETTLE"}
                      </Button>
                      <Button
                        className="btn m-1"
                        style={{
                          width: "80px",
                          backgroundColor: "#DDF2FD",
                          color: "#192655",
                          border: "1px #192655",
                        }}
                        onClick={() => {
                          TopUp(item.id , item.settlementAmount)
                        }}
                      >
                        {'TOP-UP'}
                      </Button>
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.querySelector(".tooltip-text").style.visibility = "visible";
                          e.currentTarget.querySelector(".tooltip-text").style.opacity = "1";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.querySelector(".tooltip-text").style.visibility = "hidden";
                          e.currentTarget.querySelector(".tooltip-text").style.opacity = "0";
                        }}
                      >
                        <Button
                          style={{
                            borderRadius: "5px",
                            border: "1px none",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            color: "rgba(255, 164, 27, 1)",
                            borderColor: "rgba(255, 255, 255, 1)",
                          }}
                          className="btn btn-sm rounded-100 m-0 p-1"
                          onClick={() => {
                            calculateSettlement(item.id);
                          }}
                        >
                          <div className="hover-container">
                            <a>
                              <img
                                src={Image2}
                                alt="Clickable Image"
                              />
                            </a>
                          </div>
                        </Button>
                        <span
                          style={{
                            visibility: "hidden",
                            width: "100px",
                            backgroundColor: "#555",
                            color: "#fff",
                            textAlign: "center",
                            borderRadius: "6px",
                            padding: "5px 0",
                            position: "absolute",
                            zIndex: "1",
                            bottom: "125%",
                            left: "50%",
                            marginLeft: "-50px",
                            opacity: "0",
                            transition: "opacity 0.3s",
                          }}
                          className="tooltip-text"
                        >
                          Calculate Settlement
                        </span>
                      </div>
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.querySelector(".tooltip-text").style.visibility = "visible";
                          e.currentTarget.querySelector(".tooltip-text").style.opacity = "1";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.querySelector(".tooltip-text").style.visibility = "hidden";
                          e.currentTarget.querySelector(".tooltip-text").style.opacity = "0";
                        }}
                      >
                        <Button
                          style={{
                            borderRadius: "5px",
                            border: "1px none",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            color: "rgba(255, 164, 27, 1)",
                            borderColor: "rgba(255, 255, 255, 1)",
                          }}
                          className="btn btn-sm rounded-100 m-0 p-1"
                          onClick={() => {
                            calculateSettlement(item.id);
                          }}
                        >
                          <div className="hover-container">
                            <a>
                              <img
                                src={require("../assets/images/Update Access.png")}
                                alt="Clickable Image"
                              />
                            </a>
                          </div>
                        </Button>

                        <span
                          style={{
                            visibility: "hidden",
                            width: "100px",
                            backgroundColor: "#555",
                            color: "#fff",
                            textAlign: "center",
                            borderRadius: "6px",
                            padding: "5px 0",
                            position: "absolute",
                            zIndex: "1",
                            bottom: "125%",
                            left: "50%",
                            marginLeft: "-50px",
                            opacity: "0",
                            transition: "opacity 0.3s",
                          }}
                          className="tooltip-text"
                        >
                          Settlement 1
                        </span>
                      </div>
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.querySelector(".tooltip-text").style.visibility = "visible";
                          e.currentTarget.querySelector(".tooltip-text").style.opacity = "1";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.querySelector(".tooltip-text").style.visibility = "hidden";
                          e.currentTarget.querySelector(".tooltip-text").style.opacity = "0";
                        }}
                      >
                        <Button
                          style={{
                            borderRadius: "5px",
                            border: "1px none",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            color: "rgba(255, 164, 27, 1)",
                            borderColor: "rgba(255, 255, 255, 1)",
                          }}
                          className="btn btn-sm rounded-100 m-0 p-1"
                          onClick={() => {
                            initiateSettlement(item.id, "2", item.settlement_status_2);
                          }}
                        >
                          <div className="hover-container">
                            <a>
                              <img
                                src={require("../assets/images/Rehit.png")}
                                alt="Clickable Image"
                              />
                            </a>
                          </div>
                        </Button>
                        <span
                          style={{
                            visibility: "hidden",
                            width: "100px",
                            backgroundColor: "#555",
                            color: "#fff",
                            textAlign: "center",
                            borderRadius: "6px",
                            padding: "5px 0",
                            position: "absolute",
                            zIndex: "1",
                            bottom: "125%",
                            left: "50%",
                            marginLeft: "-50px",
                            opacity: "0",
                            transition: "opacity 0.3s",
                          }}
                          className="tooltip-text"
                        >
                          Settlement 2
                        </span>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          {data.length <= 0 && <NoDataFound />}
          <hr />
          <Pagination
            page={currentPage}
            limit={pages}
            callback={(page, offsetPassed) => {
              if (currentPage != page) setCurrentPage(page);
              if (offset != offsetPassed) setOffset(offsetPassed);
            }}
            count={dataToDisplay.length}
            total={total}
          />
        </div>
      </div>
    </div>
  );
};

export default SettlementPay;

export const NoDataFound = () => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center p-3">
      <Lottie
        animationData={ComingSoonImage}
        alt="Coming Soon"
        style={{ height: "40vh", width: "100vw" }}
      />
      <strong>No Data Found</strong>
    </div>
  );
};
